import { t } from '../lang';

class Str {

    /**
     * return new Date object
     * @param {int|string} date 
     * @returns {Date}
     */
    newDate(date) {
        if (this.isString(date) && date.indexOf('.') === 2) {
            // example: 18.03.1986 03:40 -> new Date
            let fullSplit = date.split(' ');
            let dateSplit = fullSplit[0].split('.');
            let timeSplit = fullSplit[1] ? fullSplit[1].split(':') : [];

            return new Date(dateSplit[2], dateSplit[1] - 1, dateSplit[0], timeSplit[0] || null, timeSplit[1] || null);
        }

        if (Number.isInteger(date) && date < 1000000000000) {
            date *= 1000;
        }

        return new Date(date);
    }

    /**
     * Format unixTimestamp to string
     * @param {int|string} date 
     * @returns {string}
     */
    date(date) {
        if (!date) {
            return date;
        }

        var date = this.newDate(date);

        var day = '0' + date.getDate();
        var month = '0' + (date.getMonth() + 1);
        var year = date.getFullYear();

        return day.slice(-2) + '.' + month.slice(-2) + '.' + year;
    }

    /**
     * Format unixTimestamp to string
     * @param {int|string} date
     * @returns {string}
     */
    datetime(date) {
        if (!date) {
            return date;
        }

        var date = this.newDate(date);

        var day = '0' + date.getDate();
        var month = '0' + (date.getMonth() + 1);
        var year = date.getFullYear();
        var hours = '0' + date.getHours();
        var minutes = '0' + date.getMinutes();

        return day.slice(-2) + '.' + month.slice(-2) + '.' + year + ' ' + hours.slice(-2) + ':' + minutes.slice(-2);
    }

    /**
     * Форматирует дату в виде строки вида "сегодня в 11:00", "вчера в 12:00" и т.д.
     * @param {int|string} date 
     * @returns {string}
     */
    dtText(date) {
        if (!date) {
            return date;
        }

        var inputDate = this.newDate(date);
        var currentDate = new Date();

        // Вычисляем разницу в днях между текущей датой и переданной
        var diffInDays = Math.round((inputDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24));

        // Получаем часы и минуты для переданной даты
        var hours = ('0' + inputDate.getHours()).slice(-2);
        var minutes = ('0' + inputDate.getMinutes()).slice(-2);

        // Определяем текстовый формат даты
        if (diffInDays === 0) {
            return `${t('today')} ${t('at')} ${hours}:${minutes}`;
        } else if (diffInDays === 1) {
            return `${t('tomorrow')} ${t('at')} ${hours}:${minutes}`;
        } else if (diffInDays === -1) {
            return `${t('yesterday')} ${t('at')} ${hours}:${minutes}`;
        } else if (diffInDays === -2) {
            return `${t('two days ago')} ${t('at')} ${hours}:${minutes}`;
        } else {
            // Для остальных случаев используем обычный формат даты
            var day = ('0' + inputDate.getDate()).slice(-2);
            var month = ('0' + (inputDate.getMonth() + 1)).slice(-2);
            var year = inputDate.getFullYear();
            return `${day}.${month}.${year} в ${hours}:${minutes}`;
        }
    }

    /**
     * Format unixTimestamp to string
     * @param {int} seconds 
     * @returns {string}
     */
    time(seconds) {
        if (!seconds) {
            return '';
        }

        const date = new Date(seconds * 1000);
        const offsetMinutes = date.getTimezoneOffset();
        date.setMinutes(date.getMinutes() - offsetMinutes);

        return date.toISOString().slice(11, 19);
    }

    /**
     * Format unixTimestamp to string with hours and minutes
     * @param {int} seconds 
     * @returns {string}
     */
    timeHM(seconds) {
        if (!seconds) {
            return '';
        }

        const date = new Date(seconds * 1000);
        const offsetMinutes = date.getTimezoneOffset();
        date.setMinutes(date.getMinutes() - offsetMinutes);

        return date.toISOString().slice(11, 16);
    }

    /**
     * Format money
     * @param {String} str 
     * @returns 
     */
    money(value) {
        if (!value) {
            return value;
        }

        value = +value
        value = value.toLocaleString('ru-Ru').replace(',', '.');

        let valueA = value.split('.');
        if (valueA[1] && valueA[1].length === 1) {
            value += '0';
        }

        return value;
    }

    /**
     * Format money for cashier report
     * @param {String} str 
     * @returns 
     */
    moneyCashier(value) {
        if (!value) {
            return value;
        }

        value = +value
        // value = value.toLocaleString('ru-Ru').replace(',', '=');
        value = value.toLocaleString('ru-Ru', { style: 'currency', currency: 'RUB' }).replace(',', '=').replace('₽', '')

        let valueA = value.split('.');
        if (valueA[1] && valueA[1].length === 1) {
            value += '0';
        }

        // Delete &nbsp
        value = value.trim()

        return value;
    }

    /**
     * Format phone number based on its country code
     * @param {String} num Full phone number with country code
     * @returns Formatted phone number
     */
    phone(num) {
        if (!num) {
            return num;
        }

        if (typeof num !== String) {
            num = new String(num);
        }

        let number = num.match(/\d/g, ''),
            joinedNumber = number.join('')

        const countries = {
            // USA (+1)
            '1': {
                format: /^(\d{1})(\d{3})(\d{3})(\d{4})$/,
                pattern: '+$1 ($2) $3-$4'
            },
            // Spain (+34)
            '34': {
                format: /^(\d{2})(\d{3})(\d{3})(\d{3})$/,
                pattern: '+$1 $2 $3 $4'
            },
            // Bulgaria (+359)
            '359': {
                format: [
                    /^(\d{3})(\d{1})(\d{3})(\d{2})(\d{2})$/, // 11-значный номер
                    /^(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})$/   // 12-значный номер
                ],
                pattern: '+$1 $2 $3-$4-$5'
            },
            // Armenia (+374)
            '374': {
                format: /^(\d{3})(\d{2})(\d{3})(\d{3})$/,
                pattern: '+$1 $2 $3 $4'
            },
            // Belarus (+375)
            '375': {
                format: /^(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})$/,
                pattern: '+$1 ($2) $3-$4-$5'
            },
            // Ukraine (+380)
            '380': {
                format: /^(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})$/,
                pattern: '+$1 ($2) $3-$4-$5'
            },
            // Germany (+49)
            '49': {
                format: /^(\d{2})(\d{2})(\d{3})(\d{4})$/,
                pattern: '+$1 $2 $3 $4'
            },
            // Russia/Kazakhstan (+7)
            '7': {
                format: /^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/,
                pattern: '+$1 ($2) $3-$4-$5'
            },
            // Türkiye (+90)
            '90': {
                format: /^(\d{2})(\d{3})(\d{3})(\d{2})(\d{2})$/,
                pattern: '+$1 ($2) $3-$4-$5'
            },
            // UAE (+971)
            '971': {
                format: [
                    /^(\d{3})(\d{1})(\d{3})(\d{2})(\d{2})$/,
                    /^(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})$/
                ],
                pattern: '+$1 $2 $3-$4-$5'
            },
            // Georgia (+995)
            '995': {
                format: /^(\d{3})(\d{3})(\d{2})(\d{2})(\d{2})$/,
                pattern: '+$1 $2 $3 $4 $5'
            },
        };

        for (let key in countries) {
            const countryRule = countries[key];

            // Проверка соответствия номера регулярному выражению
            if (joinedNumber.startsWith(key)) {
                if (Array.isArray(countryRule.format)) {
                    // Пробуем применить каждое регулярное выражение из массива
                    for (const regex of countryRule.format) {
                        if (regex.test(joinedNumber)) {
                            return joinedNumber.replace(regex, countryRule.pattern);
                        }
                    }
                } else {
                    // Применяем одиночное регулярное выражение
                    if (countryRule.format.test(joinedNumber)) {
                        return joinedNumber.replace(countryRule.format, countryRule.pattern);
                    }
                }
            }
        }

        console.warn('Неподдерживаемый код страны или неверный номер телефона.');
        return joinedNumber;

    }

    /**
     * Copy
     * @param {String} string 
     * @return
     */
    copy(textId) {
        let copyText = document.getElementById(textId).textContent;
        navigator.clipboard.writeText(copyText);
    }

    /**
     * Format snils
     * @param {Integer} int 
     * @returns 
     */
    snils(num) {
        if (!num) {
            return num;
        }

        let number = num.match(/\d/g, ''),
            joinedNumber = number.join(''),
            regex = /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
            result = joinedNumber.replace(regex, '$1-$2-$3 $4')

        return result;
    }

    /**
     * Format numbers (20 symbols)
     * @param {Integer} int 
     * @returns 
     */
    numbers(num) {
        if (!num) {
            return num;
        }

        let number = num.match(/\d/g, ''),
            joinedNumber = number.join(''),
            regex = /^(\d{4})(\d{4})(\d{4})(\d{4})(\d{4})$/,
            result = joinedNumber.replace(regex, '$1 $2 $3 $4 $5')

        return result;
    }

    /**
     * Format string
     * @param {String} str 
     * @param {String} format 
     * @returns {string}
     */
    format(str, format) {
        if (format === 'date') {
            return this.date(str);
        } else if (format === 'datetime') {
            return this.datetime(str);
        } else if (format === 'dtText') {
            return this.dtText(str);
        } else if (format === 'time') {
            return this.time(str);
        } else if (format === 'timeHM') {
            return this.timeHM(str);
        } else if (format === 'money') {
            return this.money(str);
        } else if (format === 'phone') {
            return this.phone(str);
        } else if (format === 'snils') {
            return this.snils(str);
        } else if (format === 'truncateText') {
            return this.truncateText(str);
        }

        return str;
    }

    /**
     * Get pathname without /create.. /update.. /123..
     * @returns {string}
     */
    topPath() {
        let path = document.location.pathname
        let pathA = path.split('/create')
        path = pathA[0]

        pathA = path.split('/update')
        path = pathA[0]

        pathA = path.split('/')
        if (pathA.length > 2) {
            let last = pathA[pathA.length - 1]
            if (last === '' || !isNaN(+last)) {
                pathA.splice(pathA.length - 1, 1)
                path = pathA.join('/')
            }
        }

        return path
    }

    /**
     * Check string
     *
     * @param string
     * @returns {boolean}
     */
    isString(string) {
        return Object.prototype.toString.call(string) === "[object String]"
    }

    /**
     * Truncate text
     * @param {string} text 
     * @param {int} length 
     * @returns string
    */
    truncateText(text, length) {
        if (text.length > length) {
            return text.slice(0, length) + '...';
        }
        return text;
    }
}

export default new Str();