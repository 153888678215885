<template>
  <router-link v-if="userId" :to="{ name: 'UserProfile', params: { id: userId } }">
    <Avatar
      v-if="fileAvatar"
      :size="size"
      :image="fileAvatar.urlMin"
      shape="circle"
      class="icon-block"
      v-tooltip="name"
    />
    <Avatar v-else-if="name" :label="name[0]" shape="circle" :size="size" class="cursor-pointer" v-tooltip="name" />
  </router-link>
  <Avatar
    v-else-if="fileAvatar"
    :size="size"
    :image="fileAvatar.urlMin"
    shape="circle"
    class="icon-block"
    v-tooltip="name"
  />
  <Avatar v-else-if="name" :label="name[0]" shape="circle" :size="size" class="cursor-pointer" v-tooltip="name" />
</template>

<script>
import Avatar from 'primevue/avatar';

export default {
  components: {
    Avatar,
  },
  props: {
    fileAvatar: Object,
    name: String,
    userId: Number,
    size: {
      type: String,
      default: 'large',
    },
  },
};
</script>