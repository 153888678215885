import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import http from '../utils/http-common';
import Env from '../utils/Env';
import Str from '../utils/Str';

window.Pusher = Pusher;

export async function initSocketServer(listenerName, channelName, isToast = true, store, vuexStoreName, context, isAudio = false) {
    try {
        const response = await http.get(process.env.VUE_APP_API_URL + '/notification/check-available');
        const websocketsIsRunning = response.data.data.websocketsIsRunning;

        if (websocketsIsRunning) {
            const socketServer = new Echo({
                broadcaster: 'pusher',
                key: process.env.VUE_APP_PUSHER_KEY,
                cluster: 'mt1',
                encrypted: false,
                forceTLS: false,
                wsHost: process.env.VUE_APP_PUSHER_HOST,
                enabledTransports: ['ws', 'wss'],
                authEndpoint: process.env.VUE_APP_WEBSOCKETS_AUTHPOINT,
                wsPort: process.env.VUE_APP_PUSHER_PORT,
                wssPort: process.env.VUE_APP_PUSHER_PORT,
                auth: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('jwt-token'),
                    },
                },
            });

            await socketServer
                .channel(Env.getItem('VUE_APP_ID') + '.' + channelName + '.' + store.getters['auth/user'].id)
                // .bind('pusher_internal:subscription_succeeded')
                .listen('.' + listenerName + '.event', async (event) => {
                    if (isAudio) {
                        var audio = new Audio('/audio/Two-Tone.mp3');
                        audio.play().catch(function (error) {
                            console.log('Sound play error:', error);
                        });
                    }

                    if (vuexStoreName) {
                        store.commit(vuexStoreName, event);
                    }

                    if (isToast) {
                        // @todo: вытащить и передавать отдельно
                        context.$toast.add({
                            severity: 'info',
                            detail: Str.truncateText(event.text, 35),
                            summary: event.userName,
                            group: 'bl',
                            life: 10000,
                        });

                        navigator.serviceWorker.ready.then((registration) => {
                            const pushOptions = {
                                body: Str.truncateText(event.text, 35),
                                icon: '/img/icons/android-chrome-192x192.png',
                                badge: '/img/icons/android-chrome-192x192.png',
                            };
                            registration.showNotification('Push Уведомление', pushOptions);
                            console.log('Push Уведомление');
                        });
                    }
                });
        } else {
            console.log('Websockets is not running');
        }
    } catch (error) {
        console.log(error, 'error');
    }
}